import React, { useMemo } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import cx from "classnames";
import theme from "../../constants/theme";
import Accordion from "../../components/shared/Accordion";
import { Wrapper, SectionHeading } from "../../components/core";
import {
  DummyDesc,
  FaqList,
  DataFaq,
} from "../../components/pageShared/FaqSampleData";
import { useIntl } from "gatsby-plugin-intl";

const FaqDetail = ({ location }) => {
  const intl = useIntl();
  const params = new URLSearchParams(location.search);

  const pageTitle = useMemo(() => {
    if (!params) return "";
    const faqId = parseInt(params.get("faqId")) || 0;
    const topic = DataFaq.find(item => item.Id === faqId);
    if (topic) {
      return topic[`Title_${intl.locale}`];
    }
  }, [params, DataFaq]);

  return (
    <Layout>
      <SEO title={pageTitle} />
      <section className={cx("bg-bg2", theme.spacing.sectionY)}>
        <Wrapper>
          <div className="flex flex-col">
            <SectionHeading
              mainText={pageTitle}
              textColor={theme.colors.accent1}
              lineColor={theme.colors.accent2}
            />

            <div className={cx("bg-transparent", theme.spacing.blockY)} />

            <div className="flex flex-col md:flex-row mt-5">
              <div className="md:w-full">
                <div className="container mx-auto">
                  <div className="md:mx-8 rounded overflow-hidden">
                    {FaqList.map((_faq, idx) => (
                      <Accordion
                        key={idx}
                        title={_faq[`Title_${intl.locale}`]}
                        content={DummyDesc[intl.locale]}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </section>
    </Layout>
  );
};

export default FaqDetail;
