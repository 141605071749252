export const DataFaq = [
  {
    Id: 1,
    Title_en: "Account",
    Title_ja: "アカウント",
    Topics: [
      {
        Title_en: "Account Creation",
        Title_ja: "アカウントの作成",
      },
      {
        Title_en: "Account Login",
        Title_ja: "アカウントログイン",
      },
      {
        Title_en: "Forgot Password",
        Title_ja: "パスワードをお忘れですか",
      },
    ],
  },
  {
    Id: 2,
    Title_en: "Payment & Plans",
    Title_ja: "支払いとプラン",
    Topics: [
      {
        Title_en: "Payment Types",
        Title_ja: "支払いタイプ",
      },
      {
        Title_en: "Promo Codes",
        Title_ja: "プロモーションコード",
      },
      {
        Title_en: "When I'll be charged?",
        Title_ja: "いつ請求されますか？",
      },
    ],
  },
  {
    Id: 3,
    Title_en: "Dashboard",
    Title_ja: "ダッシュボード",
    Topics: [
      {
        Title_en: "My Calendar",
        Title_ja: "マイカレンダー",
      },
      {
        Title_en: "My Agile",
        Title_ja: "マイカンバン",
      },
      {
        Title_en: "My Tasks",
        Title_ja: "マイタスク",
      },
    ],
  },
  {
    Id: 4,
    Title_en: "Our product",
    Title_ja: "主な機能の紹介",
    Topics: [
      {
        Title_en: "Highly secure",
        Title_ja: "非常に安全",
      },
      {
        Title_en: "Centralized collaboration",
        Title_ja: "一元管理される共同作業",
      },
      {
        Title_en: "Calendar and Kanban",
        Title_ja: "カレンダーとカンバン",
      },
    ],
  },
  {
    Id: 5,
    Title_en: "Return & Refund",
    Title_ja: "返品と返金",
    Topics: [
      {
        Title_en: "How do i get refund?",
        Title_ja: "払い戻しを受けるにはどうすればよいですか？",
      },
      {
        Title_en: "Service Issue",
        Title_ja: "サービスの問題",
      },
      {
        Title_en: "Contact to admin",
        Title_ja: "管理者への連絡",
      },
    ],
  },
  {
    Id: 6,
    Title_en: "Technical",
    Title_ja: "テクニカル",
    Topics: [
      {
        Title_en: "Trouble to signin",
        Title_ja: "サインインの問題",
      },
      {
        Title_en: "Trouble with the website",
        Title_ja: "ウェブサイトのトラブル",
      },
      {
        Title_en: "Browser Support",
        Title_ja: "ブラウザのサポート",
      },
    ],
  },
];

export const FaqList = [
  {
    Title_en: "How to register yourself?",
    Title_ja: "自分を登録する方法は？",
  },
  {
    Title_en: "How to log in or out?",
    Title_ja: "ログインまたはログアウトする方法は？",
  },
  {
    Title_en: "How to manage your subscription?",
    Title_ja: "サブスクリプションを管理する方法は？",
  },
  {
    Title_en: "How to manage your notificationa?",
    Title_ja: "通知を管理する方法は？",
  },
  {
    Title_en: "How to edit your profile?",
    Title_ja: "プロフィールを編集する方法?",
  },
  {
    Title_en: "How to download the media?",
    Title_ja: "メディアをダウンロードする方法は？",
  },
  {
    Title_en: "How to delete my account?",
    Title_ja: "アカウントを削除するにはどうすればよいですか？",
  },
];

export const DummyDesc = {
  en:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  ja:
    "Lorem Ipsumは、印刷および植字業界の単なるダミーテキストです。 Lorem Ipsumは、未知のプリンターがタイプのギャレーを取り、それをスクランブルしてタイプの見本帳を作成した1500年代以来、業界の標準的なダミーテキストでした。それは5世紀だけでなく、電子植字への飛躍にも耐えてきましたが、本質的には変わっていません。 1960年代に、Lorem Ipsumのパッセージを含むLetrasetシートがリリースされ、最近では、LoremIpsumのバージョンを含むAldusPageMakerなどのデスクトップパブリッシングソフトウェアで普及しました。",
};
